import '@fortawesome/fontawesome-svg-core/styles.css'
import '../styles/globals.css'

import { config } from '@fortawesome/fontawesome-svg-core'
import { AppProps } from 'next/app'
import { Inter } from 'next/font/google'
import Script from 'next/script'
import { FC, useMemo } from 'react'
import { createClient, fetchExchange, Provider as UrqlProvider } from 'urql'

import { useGoogleAnalytics } from '../components/useGoogleAnalytics'

config.autoAddCss = false
const inter = Inter({ subsets: ['latin'] })
const App: FC<AppProps> = ({ Component, pageProps }) => {
  const client = useMemo(
    () =>
      createClient({
        url: process.env.NEXT_PUBLIC_API_ENDPOINT as string,
        requestPolicy: 'cache-and-network',
        exchanges: [fetchExchange],
      }),
    [],
  )

  useGoogleAnalytics()

  if (!client) {
    return null
  }

  return (
    <div className={inter.className}>
      <UrqlProvider value={client}>
        <Script
          id="linkedin"
          dangerouslySetInnerHTML={{
            __html: `_linkedin_partner_id = "1540524";
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);
(function(l) {
if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
window.lintrk.q=[]}
var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})(window.lintrk);`,
          }}
        />
        <noscript>
          <img
            height="1"
            width="1"
            className="hidden"
            alt="LinkedIn Insight Tag"
            src="https://px.ads.linkedin.com/collect/?pid=1540524&fmt=gif"
          />
        </noscript>
        <Script
          id="facebook-pixel-id"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '426471222960788');
  fbq('track', 'PageView');
            `,
          }}
        />
        <img
          className="hidden"
          alt="Facebook Pixel"
          src={`https://www.facebook.com/tr?id=426471222960788&ev=PageView&noscript=1`}
          height="1"
          width="1"
        />
        <Component {...pageProps} />
      </UrqlProvider>
    </div>
  )
}

export default App
